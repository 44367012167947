.container-border{
    border: 1px solid #ccc;
    border-radius: 15px;
    margin-top: 15px;
    padding-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 20px;
}



.modal-lg {
    max-width: 75% !important;
}

.theme{
    background-color: #5f161d;
    color: white;
    outline: none !important;
    border-color: #5f161d;
}

.theme:hover{
    background-color: #5f161d;
    color: white;
    outline: none !important;
}


.theme-color{
    color: #4d1a22;
}

a.nav-link:hover { cursor: pointer; }

.active {
    background: rgba(165, 160, 176, 0.74); color:#000;
}

.btn-outline {
    border-color: #8F2C37;
    color: #8F2C37;
    background-color: white;
    border-radius: 5px;
}

.btn-outline:hover {
    background-color: #8F2C37;
    color: white;
}

a{
    text-decoration: none;
}

a:hover{
    text-decoration: none !important;
}
.submenu-bar{
    background-color: #000000;
}

.selected-menu{
    background-color: #580018;
}

.nav {
    position: relative;
    color: white;
    background: -webkit-linear-gradient(#1a191a, #1a191a);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#1a191a, #1a191a);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#1a191a, #1a191a);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#1a191a, #1a191a);
    /* Standard syntax (must be last) */
    box-shadow: 0 0 3px 0px rgba(40, 39, 40, 0.89);
    position: -webkit-sticky;
    z-index: 1;
}

.nav button {
    padding: 10px;
    background: #580018;
    color: white;
    border-style: solid;
    border-top-style: none;
    border-color: white;
    border-width: 1px;
    margin-left: 47%;
    margin-bottom: 15px;
}

.nav button:hover {
    background: #D3B663;
}

.nav-wrapper {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.nav ul li {
    display: inline-block;
}

.nav ul li:hover {
    background-color: rgba(88, 0, 24, 0.99);
}

.nav ul li a,
visited {
    color: white;
    display: block;
    padding: 15px;
    text-decoration: none;
}

.nav ul li a:hover {
    color: white;
    text-decoration: none;
}

.nav ul li:hover > ul {
    display: block;
}

.nav ul ul {
    display: none;
    position: absolute;
    background-color: rgba(110, 1, 6, 0.9);
    min-width: 100px;
}

.nav ul ul li {
    display: block;
    text-align: left;
}

.nav ul ul li a,
visited {
    color: white;
    cursor: pointer;
}

.nav ul ul li a:hover {
    color: #D3B663;
    display: block;
}

.nav ul.submenu {
    display: none;
    position: absolute;
    left: 153px;
    top: 147px;
    width: 150px;
    text-align: center;
}

.nav ul.submenu li {
    text-align: left;
    color: white;
}

.nav li:hover ul.submenu:hover {
    color: #D3B663;
    display: block;
}

.nav-wrapper img {
    float: right;
    height: 75px;
    padding-right: 70px;
}

.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    border-top: 0;
}

.nav form {
    position: absolute;
    right: 0;
    padding-right: 75px;
    margin-top: -18px;
}

.nav input {
    border: solid;
    border-color: white;
    border-width: 1px;
    color: white;
    background-color: #2e0507;
    padding: 6px;
    padding-top: 8px;
}

::-webkit-file-upload-button {
    background: black;
    border-radius: 10px;
    color: white;
    padding: 8px;
    outline: 0 !important;
}

.bg-bronze{
    background-color: #AD8A56;
}

.bg-light-bronze{
    background-color: #D3AF93;
}

.blok{margin:30px;}

.button{
    cursor: pointer;
}